import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Card from "../../shared/components/UIElements/Card";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import logo from '../../logo.svg';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import "./Auth.css";

const Auth = () => {
  let navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );


 const authSubmitHandler = async event => {
    event.preventDefault();

      try {
       const responseData = await sendRequest(
        'https://ai-blog-api.herokuapp.com/api/users/login',
          'POST',
          JSON.stringify({
            email: formState.inputs.email.value,
            password: formState.inputs.password.value
          }),
          {
            'Content-Type': 'application/json'
          }
        );
        auth.login(responseData.userId, responseData.token);
        navigate('/dashboard');

      } catch (err) {}
    }
  
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
        {isLoading && <LoadingSpinner asOverlay />}

    {!isLoading && <Card className="authentication">
        <div className="logo-container">
            <img src={logo} alt="logo"/>
        </div>
        <form autoComplete="off" onSubmit={authSubmitHandler}>

          <Input
            element="input"
            placeholder="User"
            id="email"
            type="email"
            label="E-Mail"
            style={{backgroundColor: "#000", color:"#fff !important"}}
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email address."
            onInput={inputHandler}
          />
          <Input
            placeholder="Password"
            element="input"
            id="password"
            type="password"
            label="Password"
            style={{backgroundColor: "#000", color:"#fff !important"}}
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Please enter a valid password, at least 6 characters."
            onInput={inputHandler}
          />
          {/* <Link to="/auth/forgot-password">Passwort vergessen</Link> */}
          <br />
          <Button type="submit" disabled={!formState.isValid}>
           LOGIN
          </Button>
        </form>
      </Card>}
    </React.Fragment>
  );
};

export default Auth;
