import React from 'react';

import {
  BrowserRouter,
  Route,
  Routes,

} from 'react-router-dom';
import './App.css';
import { AuthContext } from './shared/context/auth-context';
import {useAuth} from './shared/hooks/auth-hook';
import Auth from './user/pages/Auth';
import Dashboard from './user/pages/Dashboard';
// import NewPost from './post/pages/NewPost';
// import NewCategory from './category/pages/NewCategory';
// import UpdateCategory from './category/pages/UpdateCategory';
// import UpdatePost from './post/pages/UpdatePost';yy


function App() {
  const { token, reset, login, logout, userId } = useAuth();

  let routes;


  if (token) {
    routes = (
      <Routes>
        <Route path="/" element={<Dashboard />} />
        {/* <Route path="/posts" element={<NewPost />} />
        <Route path="/categories" element={<NewCategory />} />
        <Route path="/posts/:postId" element={<UpdatePost />} />
        <Route path="/categories/:categoryId" element={<UpdateCategory />} /> */}
        <Route path="/*" element={<Dashboard />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
      <Route path="/" element={<Auth />} />
        <Route path="/*" element={<Auth />} />
      </Routes>
    );
  }

  return (
    <AuthContext.Provider
    value={{ isLoggedIn: !!token,  token: token, userId: userId, login: login, logout: logout, reset: reset }}  >
    <div className="App">
  <BrowserRouter>
 <main>{routes}</main>
  </BrowserRouter>
    </div>
    </AuthContext.Provider>
  );
}

export default App;
