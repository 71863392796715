import * as React from "react"
import './Dashboard.css';
import {Icon} from '@iconify/react';  
import { useHttpClient } from '../../shared/hooks/http-hook';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { AuthContext } from '../../shared/context/auth-context';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import {useForm} from "../../shared/hooks/form-hook";

const Dashboard = () => {
    const auth = React.useContext(AuthContext);
    const [formState, inputHandler, setFormData] = useForm({
        postSearch:{
            value:"",
            isValid: true
        },
        categorySearch:{
            value:"",
            isValid: true
        }
    },true);
    const {isLoading, error, clearError, sendRequest} = useHttpClient();
    const [loadedPosts, setLoadedPosts] = React.useState()
    const [loadedCategories, setLoadedCategories] = React.useState()
    const [action, setAction] = React.useState("default")


    const createOptions = (data, index) => {
        return(<>
            <option key={index} value={data.id}>{data.title}</option>
        </>
        )
    }
    const user = {
        name:"R4$0N3",
        email:"aiblog@miomideal.com"
    };



    const Settings = () => {
    
        const showState = () => {
           console.log(formState.inputs);
        }
    
        return(
            <div id="settings-container">
                <button onClick={showState}>FormState</button>
            </div>
        )
    }

    React.useEffect(()=>{
        const fetchData = async () => {
            try{
                const responsePosts = await sendRequest('https://ai-blog-api.herokuapp.com/api/posts');
                setLoadedPosts(responsePosts.posts);
                const responseCategories = await sendRequest('https://ai-blog-api.herokuapp.com/api/categories');
                setLoadedCategories(responseCategories.categories);
            }catch(err){}
        }

        fetchData();
    },[sendRequest])


    const postStyle = {
        background:{
            backgroundColor: "#49BCF6"
        },
        color:{
            color: "#49BCF6"
        }
    }
    const categoryStyle = {
       
        
        background:{
            backgroundColor: "#49DEB2"
        },
        color:{
            color: "#49DEB2"
        }
    
    }
    const categorySubmitHandler = async event => {
        event.preventDefault();
        console.log(auth.token);
        try{
            await sendRequest('https://ai-blog-api.herokuapp.com/api/categories',
                'POST',
                JSON.stringify({
                    title:formState.inputs.title.value
                }),
                { 'Content-Type': 'application/json', Authorization: 'Bearer ' + auth.token}
            )
        }catch(err){}
    }
    const postSubmitHandler = async event => {
            event.preventDefault();
            try{
                await sendRequest('https://ai-blog-api.herokuapp.com/api/posts',
                    'POST',
                    JSON.stringify({
                        title:formState.inputs.title.value,
                        content: formState.inputs.content.value,
                        image: formState.inputs.image.value,
                        category: formState.inputs.category.value
                    }),
                    { 'Content-Type': 'application/json', Authorization: 'Bearer ' + auth.token}
                )
            }catch(err){}
    }
    const createPosts = (data, index) => {
        const shortContent = data.content.slice(0, 50);
        return(
            <li style={postStyle.background} id={data.id} key={index}><img src={data.image} alt={data.title} /><span>{data.title} | {shortContent}</span> </li>
        )
    }
    const createCategories = (data, index) => {
        return(
            <li style={categoryStyle.background} id={data.id} key={index}><span>{data.title}</span></li>
        )
    }
    
    const handleAction = (event) => {
        
        if(event.target.id === "NewPost"){
            setFormData({
                title:{
                    value:"",
                    isValid: false
                },
                content:{
                    value:"",
                    isValid: false
                },
                category:{
                    value:"",
                    isValid: false
                },
                image:{
                    value:"",
                    isValid: false
                }
            },false);
            
        }
        if(event.target.id === "NewCategory"){
            setFormData({
                title:{
                    value:"",
                    isValid: false
                }
            },false);
        }

        setAction(event.target.id);
    }
    return(
        <>
        <Settings />
        <ErrorModal error={error} onClear={clearError} />
        {isLoading && <LoadingSpinner asOverlay />}

                <div className="Dashboard">
            <h1 style={{color:"#FF2E4C"}}>Yo {user.name} <Icon onClick={auth.logout} icon="gridicons:popout" color="#FF2E4C" width="25" height="25" /></h1>
            <div className="controls dash-container">
                <div className="controls__sub">
                    <div className="controls__search">
                        <Input id="postSearch" validators={[]} initialValid={true} style={postStyle.background} element="input" type="text" placeholder='POSTS' onInput={inputHandler} />
                    </div>
                    <div className="controls__buttons">
                    <button id="NewPost" onClick={handleAction} style={postStyle.background} type="button"><Icon icon="gridicons:create" color="#000" width="25" height="25" /></button>
                    <button id="EditPost" onClick={handleAction} style={postStyle.background} type="button"><Icon icon="gridicons:caption" color="#000" width="25" height="25" /></button>
                    <button id="PublishPost" onClick={handleAction} style={postStyle.background} type="button"><Icon icon="gridicons:grid" color="#000" width="25" height="25" /></button>
                    <button id="DeletePost" onClick={handleAction} style={postStyle.background} type="button"><Icon icon="gridicons:trash" color="#000" width="25" height="25" /></button>
                    </div>
                    </div>
                <div className="controls_sub">
                    <div className="controls__search">
                    <Input id="categorySearch" validators={[]} initialValid={true} style={categoryStyle.background} element="input" type="text" placeholder='CATEGORIES' onInput={inputHandler} />
                    </div>
                    <div className="controls__buttons">
                    <button id="NewCategory" onClick={handleAction} style={categoryStyle.background} type="button"><Icon icon="gridicons:create" color="#000" width="25" height="25" /></button>
                    <button id="EditCategory" onClick={handleAction} style={categoryStyle.background}type="button"><Icon icon="gridicons:caption" color="#000" width="25" height="25" /></button>
                    <button id="default" onClick={handleAction} style={categoryStyle.background}type="button"><Icon icon="gridicons:grid" color="#000" width="25" height="25" /></button>
                    <button id="DeleteCategory" onClick={handleAction} style={categoryStyle.background}type="button"><Icon icon="gridicons:trash" color="#000" width="25" height="25" /></button>
                    </div>
                    </div>

             
            </div>
            <h1 style={{color:"#FF2E4C"}}>Create some</h1>
            <div className="action-container dash-container">
                {action === "default" && <div className="overview-container">
                        <ul>
                            {loadedPosts && loadedPosts.map(createPosts)}
                        </ul>
                        <ul>
                            {loadedCategories && loadedCategories.map(createCategories)}
                        </ul>
                    </div>}
               {action === "NewPost" && <form className="dash-form" onSubmit={postSubmitHandler}>
                    <Input
                        element="input"
                        type="text"
                        id="title"
                        placeholder="Title"
                        style={postStyle.background}
                        validators={[]}
                        errorText="Enter a valid title."
                        onInput={inputHandler}
                     />
                    <Input
                        element="input"
                        type="text"
                        id="category"
                        placeholder="Category"
                        datalist="categories"
                        style={postStyle.background}
                        validators={[]}
                        errorText="Enter a valid category."
                        onInput={inputHandler}
                     />
                    <Input
                        element="input"
                        type="url"
                        id="image"
                        placeholder="Image URL"
                        style={postStyle.background}
                        validators={[]}
                        errorText="Enter a valid URL."
                        onInput={inputHandler}
                     />
                     <Input
                        element="textarea"
                        type="text"
                        id="content"
                        placeholder="Content"
                        style={postStyle.background}
                        validators={[]}
                        errorText="Please enter a valid content."
                        onInput={inputHandler}
                     />
                    <Button disabled={!formState.isValid} type="submit">CREATE</Button>
                    <datalist id="categories">
        {loadedCategories.map(createOptions)}
        </datalist>
                </form>}
               {action === "NewCategory" && <form autoComplete="off" className="dash-form" onSubmit={categorySubmitHandler}>
                    <Input
                        element="input"
                        type="text"
                        id="title"
                        placeholder="Title"
                        style={categoryStyle.background}
                        validators={[]}
                        errorText="Enter a valid title."
                        onInput={inputHandler}
                     />
                    <Button disabled={!formState.isValid} type="submit">CREATE</Button>
                </form>}
            </div>
        </div>

     
        </>
    )
}


export default Dashboard;